<template>
<div class="page" :style="style">
  <div class="loading" v-show="loading">{{ textcontent }}</div>
  <div class="blind-page" :style="{ minHeight: wH + 'px' }">
    <div class="lamp-line lamp-left" :class="`${routeName}-line`"></div>
    <div class="lamp-line lamp-right" :class="`${routeName}-line`"></div>
    <div class="upper lamp-top"></div>
    <div class="upper lamp-bottom"></div>
    <!-- <y-scroller
    :is-loading="isLoading"
     @pulldown="pulldown">
      <router-view
      ref="view"  slot="list"></router-view>
    </y-scroller> -->
    <router-view :loading="loading"
      ref="view"></router-view>
  </div>
</div>
</template>
<script>
import YScroller from '@/components/scroll/scroller';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'BlindBox',
  data() {
    return {
      loading: false,
      style: {},
      textcontent: '',
      disabledScroll: false,
      wH: 0,
      routeName: '',
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.routeName = val.name;
      },
      immediate: true,
    },
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '1e893452-da8a-44fa-aaeb-7f7cc6bd02b4';
    },
    activityId() {
      return 2;
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    YScroller,
  },
  created() {
    this.blindMonitor();
  },
  mounted() {
    document.title = '鱼你拆盲盒';
    this.$nextTick((_) => {
      this.pageScroll();
      this.wH = document.body.clientHeight;
    });
  },
  methods: {
    blindMonitor() {
      window._monitor('init', {
        businessType: 0,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
    },
    loadingAction() {
      this.loading = false;
    },
    pageScroll() {
      let startPosition = 0;
      const page = document.querySelector('.page');
      let distance = 0;
      page.addEventListener('touchstart', (e) => {
        // e.stopPropagation();
        if (this.disabledScroll) return false;
        console.log('下拉刷新');
        this.textcontent = '下拉刷新';
        // console.log(e);
        startPosition = e.touches[0].pageY;
        // e.stopPropagation();
      });
      page.addEventListener('touchmove', (e) => {
        // e.stopPropagation();
        if (this.disabledScroll) return false;
        let currentPosition = e.touches[0].pageY;
        distance = currentPosition - startPosition;
        if (distance <= 0) return false;
        // if (distance <= 0) return false;
        // console.log('distance', distance);
        if (distance > 80) {
          this.textcontent = '释放立即刷新';
          this.loading = true;
        }
        if (distance > 80 && distance < 150) {
          this.loading = true;
          this.style = {
            transition: 'transform .6s',
            transform: 'translateY(.5rem)',
          };
        }
      });
      page.addEventListener('touchend', (e) => {
        // let currentPosition = e.touches[0].pageY;
        // distance = currentPosition - startPosition;
        // e.stopPropagation();
        if (this.disabledScroll) return false;
        if (distance > 0 && distance < 80) {
          this.style.transform = 'translateY(0)';
          this.loading = false;
          return false;
        }
        if (distance > 80) {
          this.style.transform = 'translateY(1rem)';
          this.textcontent = '正在刷新...';
          this.loading = true;
          setTimeout(() => {
            this.style.transform = 'translateY(0)';
            this.loading = false;
            distance = 0;
          }, 400);
        }
      });
    },
    pulldown() {
      this.$refs.view.loadDataAction();
      this.isLoading = true;
      window.setTimeout(() => {
        this.isLoading = true;
      }, 500);
    },
  },
};
</script>
<style scoped lang="scss">
.blind-page {
  max-width: 750px;
  min-height: 100%;
  background: #010104;
  margin: auto;
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 20;
  // -webkit-overflow-scrolling: touch;
}
.page {
  position: relative;
}
.loading {
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 20px 0;
  position: absolute;
  left: 50%;
  top: -.8rem;
  transform: translateX(-50%);
  z-index: 10;
}
.lamp-line {
    position: absolute;
    // content: '';
    width: 15px;
    // height: calc(100% - 90px);
    height: 1999px;
    background-size: contain;
    background-repeat: repeat;
    top: 40px;
    z-index: 100;
    &.lamp-left {
      left: 7px;
      background-image:
      url('//download-cos.yofish.com/blind-gongjushiyebu/20220728140746865-lamp-left.png');
      &.Prize-line {
        height: calc(100% - 90px);
        background-image:
      url('//download-cos.yofish.com/blind-gongjushiyebu/20220729105453202-prize-left-line.png');
      }
    }
    &.lamp-right {
      right: 7px;
      background-image:
      url('//download-cos.yofish.com/blind-gongjushiyebu/20220728144206815-lamp-right.png');
      &.Prize-line {
        height: calc(100% - 90px);
        background-image:
      url('//download-cos.yofish.com/blind-gongjushiyebu/20220729105630402-prize-right-line.png');
      }
    }
  }
  .upper {
    height: 15px;
    width: 716px;
    background-image:
      url('//download-cos.yofish.com/blind-gongjushiyebu/2022072814012596-lamp-upper.png');
    background-size: cover;
    background-repeat: no-repeat;
    // margin: 16px auto 0;
    z-index: 200;
    position: relative;
    &.lamp-top {
      // margin: 16px auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 16px;
    }
    &.lamp-bottom {
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
