<template>
  <div class="nut-scroller">
    <template v-if="type === 'vertical'">
      <vert-scroll
        :stretch="stretch"
        :is-un-more="isUnMore"
        :is-loading="isLoading"
        :threshold="threshold"
        :pulldown-txt="pulldownTxt"
        :load-more-txt="loadMoreTxt"
        :loading-txt="loadingTxt"
        :unload-more-txt="unloadMoreTxt"
        :props-time="propsTime"
        :scroll-to="scrollTo"
        @loadMore="loadMore"
        @pulldown="pulldown"
        @scrollToCbk="scrollToCbk"
        @scrollChange="scrollChange"
      >
        <slot name="list" slot="list"></slot>
      </vert-scroll>
    </template>
  </div>
</template>
<script>
import vertScroll from './vertical-scroll.vue';

export default {
  name: 'Scroller',
  props: {
    type: {
      type: String,
      default: 'vertical',
    },
    stretch: {
      type: Number,
      default: 100,
    },
    isUnMore: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    threshold: {
      type: Number,
      default: 100,
    },
    pulldownTxt: {
      type: String,
      default: '下拉刷新',
    },
    loadMoreTxt: {
      type: String,
      default: '上拉加载',
    },
    loadingTxt: {
      type: String,
      default: '加载中...',
    },
    unloadMoreTxt: {
      type: String,
      default: '没有更多了',
    },
    propsTime: {
      type: Number,
      default: 0,
    },
    scrollTo: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  components: {
    [vertScroll.name]: vertScroll,
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    },

    jump() {
      this.$emit('jump');
    },

    pulldown() {
      this.$emit('pulldown');
    },

    scrollToCbk() {
      this.$emit('scrollToCbk');
    },
    scrollChange(event) {
      this.$emit('scrollChange', event);
    },
  },
};
</script>
